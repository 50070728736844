import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "tailwindcss/tailwind.css";

const Blogs = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="min-h-1/2 py-12">
      {/* Meta tags for SEO */}
      <meta name="description" content="Explore Web IQ blogs for insights on SEO, web development, and more." />
      <meta name="keywords" content="SEO, Web Development, Digital Marketing" />
      <meta name="author" content="WebIQ" />

      {/* Blog Cards Section */}
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-gray-900 mb-10">
          Latest Blog Articles
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {/* Article 1 */}
          <Link
            to="/blog/seo-success"
            className="group relative bg-white rounded-lg shadow-lg overflow-hidden transition-all transform hover:-translate-y-3 hover:shadow-2xl duration-500"
            data-aos="fade-up"
          >
            <div className="relative overflow-hidden h-64">
              <img
                src="https://ik.imagekit.io/zfayt6inj/pexels-photo-270637.jpeg?updatedAt=1729494684798"
                alt="SEO Success"
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent opacity-50"></div>
              <div className="absolute top-4 left-4 text-white text-xs uppercase font-semibold">
                SEO Guide
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition duration-500">
                Rank Your Website on Google: A Complete SEO Guide
              </h3>
            </div>
            <div className="absolute top-0 left-0 h-2 w-full bg-gradient-to-r from-blue-500 to-green-500"></div>
          </Link>

          {/* Article 2 */}
          <Link
            to="/blog/hire-web-developer"
            className="group relative bg-white rounded-lg shadow-lg overflow-hidden transition-all transform hover:-translate-y-3 hover:shadow-2xl duration-500"
            data-aos="fade-up"
          >
            <div className="relative overflow-hidden h-64">
              <img
                src="https://ik.imagekit.io/zfayt6inj/pexels-photo-6963944.webp?updatedAt=1729494561053"
                alt="Web Developer"
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent opacity-50"></div>
              <div className="absolute top-4 left-4 text-white text-xs uppercase font-semibold">
                Development
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition duration-500">
                Why Hire a Web Developer: Build a Strong Online Presence
              </h3>
            </div>
            <div className="absolute top-0 left-0 h-2 w-full bg-gradient-to-r from-blue-500 to-green-500"></div>
          </Link>

          {/* Article 3 */}
          <Link
            to="/blog/earn-money-instagram"
            className="group relative bg-white rounded-lg shadow-lg overflow-hidden transition-all transform hover:-translate-y-3 hover:shadow-2xl duration-500"
            data-aos="fade-up"
          >
            <div className="relative overflow-hidden h-64">
              <img
                src="https://ik.imagekit.io/zfayt6inj/pexels-photo-248533.jpeg?updatedAt=1729494414332"
                alt="Instagram Money"
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent opacity-50"></div>
              <div className="absolute top-4 left-4 text-white text-xs uppercase font-semibold">
                Social Media
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition duration-500">
                Earn Money from Instagram in 2025 with WebIQ
              </h3>
            </div>
            <div className="absolute top-0 left-0 h-2 w-full bg-gradient-to-r from-blue-500 to-green-500"></div>
          </Link>

          {/* Article 4 */}
          <Link
            to="/blog/future-of-app-development"
            className="group relative bg-white rounded-lg shadow-lg overflow-hidden transition-all transform hover:-translate-y-3 hover:shadow-2xl duration-500"
            data-aos="fade-up"
          >
            <div className="relative overflow-hidden h-64">
              <img
                src="https://ik.imagekit.io/zfayt6inj/pexels-photo-89955.webp?updatedAt=1729495583647"
                alt="App Development"
                className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black via-transparent opacity-50"></div>
              <div className="absolute top-4 left-4 text-white text-xs uppercase font-semibold">
                App Development
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition duration-500">
                Future of App Development: 2025 Trends & Technologies
              </h3>
            </div>
            <div className="absolute top-0 left-0 h-2 w-full bg-gradient-to-r from-blue-500 to-green-500"></div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
