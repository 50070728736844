import React from 'react';
import { Link , useLocation } from 'react-router-dom';
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaChevronRight } from 'react-icons/fa';

const Footer = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  return (
    <footer className="bg-gradient-to-r from-teal-700 to-blue-800 text-white py-16">
      <div className="container mx-auto px-6 grid grid-cols-1 md:grid-cols-4 gap-8 text-left">
        
        {/* About Section */}
        <div>
          <h3 className="text-3xl font-bold mb-6 text-teal-300">About Us</h3>
          <p className="text-lg text-gray-200 mb-6 leading-relaxed">
            At WebIQ, we are committed to crafting innovative digital solutions that empower businesses to excel in the digital era. Our expertise spans web development, app development, and digital marketing to meet your every need.
          </p>
          <a href="#about" className="text-lg text-teal-400 hover:text-teal-300 underline transition duration-300">
            Learn More About Us &rarr;
          </a>
        </div>

        {/* Company Info */}
        <div>
          <h3 className="text-3xl font-bold mb-6 text-teal-300">Company Info</h3>
          <p className="flex items-center mb-4 text-lg">
            <FaMapMarkerAlt className="mr-2" />Shop No 130, Palika Market, Fatehabad
          </p>
          <p className="flex items-center mb-4 text-lg">
            <FaEnvelope className="mr-2" /> 
            <a href="mailto:contactus.webiq@gmail.com" className="underline hover:text-teal-300 transition duration-300">contactus.webiq@gmail.com</a>
          </p>
          <p className="flex items-center mb-4 text-lg">
            <FaPhone className="mr-2" /> 
            <a href="tel:+919812887097" className="underline hover:text-teal-300 transition duration-300">98128-87097</a>
          </p>
        </div>

        {/* Useful Links */}
        <div>
          <h3 className="text-3xl font-bold mb-6 text-teal-300">Useful Links</h3>
          <ul className="space-y-4">
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" />
              {isHomePage ? (
                <a href="#home">Home</a>
              ) : (
                <Link to="/">Home</Link>
              )}
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" />
              {isHomePage ? (
                <a href="#about">About</a>
              ) : (
                <Link to="/#about">About</Link>
              )}
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" />
              {isHomePage ? (
                <a href="#project">Projects</a>
              ) : (
                <Link to="/#project">Projects</Link>
              )}
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" />
              {isHomePage ? (
                <a href="#contact">Contact</a>
              ) : (
                <Link to="/#contact">Contact</Link>
              )}
            </li>
          </ul>
        </div>

        {/* Our Services */}
        <div>
          <h3 className="text-3xl font-bold mb-6 text-teal-300">Our Services</h3>
          <ul className="space-y-4">
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" /> <Link to="/services/digital-marketing">Digital Marketing</Link>
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" /> <Link to="/services/web-development">Web Development</Link>
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" /> <Link to="/services/app-development">App Development</Link>
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" /> <Link to="/services/seo">SEO</Link>
            </li>
            <li className="flex items-center text-lg hover:text-teal-300 transition duration-300">
              <FaChevronRight className="mr-2" /> <Link to="/services/social-media-handling">Social Media Handling</Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-12 border-t border-gray-400 pt-8 text-center">
        <p className="text-lg text-gray-400">&copy; {new Date().getFullYear()} WebIQ. All rights reserved.</p>
        <p className="text-lg text-gray-400 mt-2">Designed by WebIQ</p>
      </div>
    </footer>
  );
};

export default Footer;
