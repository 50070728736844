import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const DigitalMarketing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Hero Section */}
      <div
        className="relative flex flex-col items-center justify-center h-[600px] bg-cover bg-center"
        style={{
          backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg')`,
        }}
        data-aos="fade-in"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Increased opacity for darker overlay */}
        <div className="relative text-center z-10 p-4 sm:p-8">
          <h1 className="text-4xl sm:text-7xl font-extrabold text-white mb-2">Digital Marketing</h1> {/* Responsive font size */}
          <p className="text-lg sm:text-2xl font-bold text-sky-400 tracking-wide mb-8">Boost Your Online Presence</p>
          <a
            href="tel:+919812887097"
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-3 px-6 sm:py-4 sm:px-10 rounded-lg shadow-lg hover:bg-blue-600 transition"
          >
            Get a Free SEO Audit
          </a>

        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 px-6 md:px-12 text-left">
        <h2 className="text-4xl md:text-5xl font-extrabold text-gray-800 mb-4">Why Digital Marketing?</h2>
        <p className="text-base md:text-lg text-gray-600 max-w-3xl mb-8">
          In today's digital world, having an effective marketing strategy is crucial. Our tailored digital marketing solutions will help you reach a wider audience, enhance your brand visibility, and boost sales.
        </p>
      </section>

      {/* Key Benefits Section */}
      <section className="py-16 px-6 md:px-12 bg-white shadow-lg rounded-lg mx-4">
        <h2 className="text-4xl md:text-5xl font-extrabold text-gray-800 text-center mb-12">Our Key Benefits</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: "Targeted Strategies", description: "Reach the right audience with precision.", icon: "https://cdn-icons-gif.flaticon.com/15713/15713107.gif" },
            { title: "Enhanced Engagement", description: "Increase your interaction and conversion rates.", icon: "https://cdn-icons-gif.flaticon.com/15594/15594419.gif" },
            { title: "Analytics & Reporting", description: "Track performance and optimize continuously.", icon: "https://cdn-icons-gif.flaticon.com/14193/14193763.gif" },
            { title: "Comprehensive Support", description: "We're with you every step of the way.", icon: "https://cdn-icons-gif.flaticon.com/17091/17091777.gif" },
          ].map((item, idx) => (
            <div key={idx} className="bg-gray-100 shadow-md rounded-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="zoom-in" data-aos-delay={idx * 100}>
              <img src={item.icon} alt={item.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600 text-center">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Approach Section */}
      <section className="py-16 px-6 md:px-12 text-left">
        <h2 className="text-4xl md:text-5xl font-extrabold text-gray-800 mb-8">Our Approach</h2>
        <div className="flex flex-col lg:flex-row gap-8">
          {[
            { title: "Discovery", description: "We learn about your business and goals.", icon: "https://cdn-icons-gif.flaticon.com/15578/15578995.gif" },
            { title: "Strategy Development", description: "Crafting a strategy tailored to your needs.", icon: "https://cdn-icons-gif.flaticon.com/15578/15578995.gif" },
            { title: "Execution", description: "Implementing the strategy with precision.", icon: "https://cdn-icons-gif.flaticon.com/17675/17675704.gif" },
            { title: "Optimization", description: "Regular adjustments for optimal performance.", icon: "https://cdn-icons-gif.flaticon.com/14447/14447548.gif" },
          ].map((step, idx) => (
            <div key={idx} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-md transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="fade-up" data-aos-delay={idx * 100}>
              <img src={step.icon} alt={step.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-6 md:px-12 bg-gradient-to-r from-green-300 via-blue-200 to-indigo-300 text-center text-gray-800">
        <h2 className="text-4xl md:text-5xl font-extrabold mb-4">Ready to Elevate Your Business?</h2>
        <p className="mt-4 mb-10 text-lg md:text-xl">Contact us today to get started with our expert digital marketing services.</p>
        <a
          href="tel:+919812887097"
          className="bg-white text-teal-600 py-3 px-6 md:py-4 md:px-10 rounded-lg shadow-md hover:bg-teal-100 transition-transform transform hover:scale-105 text-lg"
        >
          Request a Free SEO Audit
        </a>

      </section>

      {/* Popup Modal for SEO Audit */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg w-11/12 md:w-1/3">
            <h3 className="text-3xl font-semibold mb-4">Free SEO Audit</h3>
            <p className="mb-4">Fill out the form below to request your free SEO audit.</p>
            <form>
              <div className="mb-4">
                <label className="block mb-2 text-lg" htmlFor="name">Name</label>
                <input className="border border-gray-300 rounded-lg w-full p-3 text-lg" type="text" id="name" required />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-lg" htmlFor="email">Email</label>
                <input className="border border-gray-300 rounded-lg w-full p-3 text-lg" type="email" id="email" required />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-lg" htmlFor="website">Website URL</label>
                <input className="border border-gray-300 rounded-lg w-full p-3 text-lg" type="text" id="website" required />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-red-500 text-white px-4 py-2 rounded-lg mr-2"
                  onClick={handleModalToggle}
                >
                  Close
                </button>
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DigitalMarketing;
