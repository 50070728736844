import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import WebDevelopment from './Pages/WebDevelopment';
import SEO from './Pages/SEO';
import DigitalMarketing from './Pages/DigitalMarketing';
import MobileAppDevelopment from './Pages/AppDevelopment';
import SocialMediaHandling from './Pages/SocialMedia';
import ScrollToTop from './Components/ScrollToTop'; // Import the ScrollToTop component
import WhatsappWidget from './Components/WhatsappWidget';
import SEOBlog from './Pages/SEOBlog';
import DevelopmentBlog from './Pages/DevlopmentBlog';
import SocialMediaBlog from './Pages/SocialMediaBlog';
import AppDevelopmentBlog from './Pages/AppDevlopmentBlog';
import Blogs from './Components/Blogs';

const Layout = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Use ScrollToTop here */}
      <WhatsappWidget />
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services/web-development" element={<WebDevelopment />} />
          <Route path="/services/seo" element={<SEO />} />
          <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/services/app-development" element={<MobileAppDevelopment />} />
          <Route path="/services/social-media-handling" element={<SocialMediaHandling />} />
          {/* blog pages */}
          <Route path="/blog/seo-success" element={<SEOBlog />} />
          <Route path="/blog/hire-web-developer" element={<DevelopmentBlog />} />
          <Route path="/blog/earn-money-instagram" element={<SocialMediaBlog />} />
          <Route path="/blog/future-of-app-development" element={<AppDevelopmentBlog />} />
        </Routes>
      </main>
      <Blogs />
      <Footer />
    </Router>
  );
};

export default Layout;
