import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import emailjs from 'emailjs-com'; // Import EmailJS

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Send email using EmailJS
    emailjs.send('service_m2i4b6n', 'template_cop7nau', formData, 'n8tygWD72LDTIgGuZ')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        // Optionally, clear the form or show a success message
        setFormData({ name: '', mobile: '', email: '', message: '' });
      }, (err) => {
        console.error('Failed to send email. Error:', err);
        // Optionally, show an error message
      });
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center"
      style={{
        background: 'linear-gradient(135deg, #ffffff, #f0f0f0)',
      }}
    >
      <div className="bg-white bg-opacity-90 rounded-lg shadow-lg p-8 flex flex-col md:flex-row w-full max-w-4xl h-auto md:h-3/4">
        {/* Left Section - Info */}
        <div className="md:w-1/2 text-left px-6 py-4" data-aos="fade-right">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Contact Us
          </h2>
          <p className="text-md text-gray-600 mb-6">
          At WebIQ, we craft professional websites that combine creativity and functionality to deliver impactful online experiences for your business.
          </p>
          <p className="text-md font-medium text-gray-700">
            Shop No 130, Palika Market, Fatehabad, Haryana, 125050
          </p>
        </div>

        {/* Right Section - Form */}
        <div className="md:w-1/2 px-6 py-4" data-aos="fade-left">
          <h3 className="text-xl font-bold mb-4 text-gray-900">Get In Touch</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-800 font-semibold mb-2 text-left">Name</label>
              <input
                type="text"
                name="name" // Set name attribute
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full px-3 py-2 border rounded-lg text-gray-800 focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-800 font-semibold mb-2 text-left">Mobile (WhatsApp)</label>
              <input
                type="number"
                name="mobile" // Set name attribute
                value={formData.mobile}
                onChange={handleChange}
                placeholder="Your Mobile"
                className="w-full px-3 py-2 border rounded-lg text-gray-800 focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-800 font-semibold mb-2 text-left">Email</label>
              <input
                type="email"
                name="email" // Set name attribute
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="w-full px-3 py-2 border rounded-lg text-gray-800 focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-800 font-semibold mb-2 text-left">Explain your Enquiry</label>
              <textarea
                name="message" // Set name attribute
                value={formData.message}
                onChange={handleChange}
                placeholder="Your Message"
                className="w-full px-3 py-2 border rounded-lg text-gray-800 focus:outline-none focus:border-blue-500 h-20"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-500 to-teal-500 text-white py-2 rounded-lg transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-teal-500 hover:to-blue-600 hover:shadow-lg"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
