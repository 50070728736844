import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS is installed

const DevelopmentBlog = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen mx-auto bg-white shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="relative">
          <img
            src="https://ik.imagekit.io/zfayt6inj/pexels-photo-6963944.webp?updatedAt=1729494561053"
            alt="Web Development"
            className="w-full h-[600px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-5">
            <h1 className="text-3xl md:text-4xl font-bold text-white text-center">Why You Should Hire a Web Developer</h1>
            <p className="mt-2 text-lg italic text-gray-300">The Key to a Successful Online Presence</p>
          </div>
        </div>

        {/* Content Section */}
        <div className="md:p-20 p-6 space-y-8">
          <p className="text-gray-800 text-lg leading-relaxed">
            In today's digital age, having a professional website is essential for any business or personal brand. Hiring a professional web developer can make all the difference in creating a website that stands out and meets your goals. Here's why investing in a web developer is a smart move.
          </p>

          {/* Sections Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-duration="800"
                className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 border-l-4 border-cyan-600 relative overflow-hidden"
              >
                <h3 className="text-2xl font-semibold mb-3 text-cyan-600 text-left">{section.title}</h3>
                <p className="text-gray-600 mb-2 text-left">{section.content}</p>
                {section.list && (
                  <ul className="list-disc pl-6 text-left mt-2 text-gray-600">
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.image && (
                  <img src={section.image} alt={section.title} className="mt-4 rounded-lg shadow transition-transform transform" />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Conclusion Section */}
        <div className="bg-cyan-100 p-6 rounded-lg shadow-lg md:mx-20 transition-shadow hover:shadow-xl md:mb-10">
          <h4 className="text-lg font-semibold text-cyan-700">Conclusion: Why a Professional Web Developer is Worth the Investment</h4>
          <p className="text-gray-700 mt-3">
            While building your own website might seem appealing, hiring a professional web developer ensures a custom design, optimized performance, and ongoing support, all of which lead to long-term success. If you're ready to enhance your online presence, consider hiring a web developer today!
          </p>
        </div>
      </div>
    </div>
  );
};

// Section data for the development article
const sections = [
  {
    title: '1. Custom Design Tailored to Your Brand',
    content: 'Hiring a web developer ensures a unique design that reflects your brand’s identity and stands out from competitors.',
    list: ['Custom Features', 'Brand Identity'],
    image: 'https://ik.imagekit.io/zfayt6inj/dev1.png?updatedAt=1729492972329',
  },
  {
    title: '2. Better User Experience (UX)',
    content: 'A web developer optimizes navigation, responsiveness, and load times to improve user experience.',
    list: ['Optimized Navigation', 'Responsive Design', 'Faster Load Times'],
    image: 'https://ik.imagekit.io/zfayt6inj/dev2.png?updatedAt=1729492972169',
  },
  {
    title: '3. Improved SEO Performance',
    content: 'A professional web developer ensures your website is SEO-friendly, from code to performance optimizations.',
    list: ['SEO-Friendly Code', 'Optimized for Speed', 'Schema Markup'],
  },
  {
    title: '4. Enhanced Security and Maintenance',
    content: 'Security is critical, and a web developer ensures your site is secure and well-maintained with SSL and regular updates.',
    list: ['SSL Certificates', 'Regular Updates', 'Backup Solutions'],
  },
  {
    title: '5. Time and Cost Efficiency',
    content: 'Hiring a developer may be more cost-effective in the long run, avoiding costly revisions and saving time.',
    list: ['Avoid Common Mistakes', 'Save Time'],
    image: 'https://ik.imagekit.io/zfayt6inj/dev5.png?updatedAt=1729492972327',
  },
  {
    title: '6. Ongoing Support and Maintenance',
    content: 'A developer provides ongoing technical support, performance monitoring, and updates to ensure your site continues to meet your goals.',
    list: ['Technical Support', 'Performance Monitoring'],
    image: 'https://ik.imagekit.io/zfayt6inj/dev7.png?updatedAt=1729492972324',
  },
  {
    title: '7. Scalability and Growth',
    content: 'A web developer ensures your website is scalable to grow with your business and meet future demands.',
    list: ['Custom Features for Growth', 'Future-Proofing'],
  },
];

export default DevelopmentBlog;
