import React, { useEffect } from 'react';
import Hero from '../Components/Hero';
import Services from '../Components/Services';
import Products from '../Components/Products';
import TeamPage from '../Components/OurTeam';
import ContactForm from '../Components/ContactUs';
import ChooseUs from './ChooseUs';

const Home = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div>
      <section id="home">
        {/* Home Section Content */}
        <Hero />
      </section>
      <section id="about">
        <ChooseUs />
      </section>
      <section id="services">
        <Services />
      </section>
      <section id="project">
        <Products />
      </section>
      <section id="team">
        <TeamPage />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
    </div>
  );
};

export default Home;
