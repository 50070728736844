import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel theme styles

const Services = () => {
  const services = [
    {
      title: 'Digital Marketing',
      description: 'Boost your business growth with tailored marketing strategies.',
      link: '/services/digital-marketing',
      icon: (
        <video
          src="https://ik.imagekit.io/zfayt6inj/laptop.mp4?updatedAt=1726804882619"
          alt="Digital Marketing Icon"
          className="w-16 h-16 rounded-lg"
          autoPlay
          loop
          muted
          playsInline
        />
      ),
    },
    {
      title: 'Web Development',
      description: 'Create responsive and visually stunning websites with us.',
      link: '/services/web-development',
      icon: (
        <video
          src="https://ik.imagekit.io/zfayt6inj/web-developer.mp4?updatedAt=1726804168332"
          alt="Web Development Icon"
          className="w-16 h-16 rounded-lg"
          autoPlay
          loop
          muted
          playsInline
        />
      ),
    },
    {
      title: 'App Development',
      description: 'Custom mobile apps designed to meet your business needs.',
      link: '/services/app-development',
      icon: (
        <video
          src="https://ik.imagekit.io/zfayt6inj/virtual-assistant.mp4?updatedAt=1726804952326"
          alt="App Development Icon"
          className="w-16 h-16 rounded-lg"
          autoPlay
          loop
          muted
          playsInline
        />
      ),
    },
    {
      title: 'SEO',
      description: 'Enhance your site’s visibility with professional SEO strategies.',
      link: '/services/seo',
      icon: (
        <video
          src="https://ik.imagekit.io/zfayt6inj/browser.mp4?updatedAt=1726804882517"
          alt="SEO Icon"
          className="w-16 h-16 rounded-lg"
          autoPlay
          loop
          muted
          playsInline
        />
      ),
    },
    {
      title: 'Social Growth',
      description: 'Manage and grow your online presence across all platforms.',
      link: '/services/social-media-handling',
      icon: (
        <video
          src="https://ik.imagekit.io/zfayt6inj/content-media.mp4?updatedAt=1726805000552"
          alt="Social Media Icon"
          className="w-16 h-16 rounded-lg"
          autoPlay
          loop
          muted
          playsInline
        />
      ),
    },
  ];

  useEffect(() => {
    AOS.init({ duration: 1200, disable: 'mobile' });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024, // Tablet screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640, // Mobile screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="relative w-full py-16 bg-gray-800">
      {/* Background Overlay */}
      <div className="absolute inset-0 bg-opacity-50 backdrop-blur-lg z-0"></div>

      <div className="container mx-auto min-w-full relative z-20 md:px-8">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-12 text-white">
          Our Services
        </h2>
        <div className="relative">
          <Slider {...settings}>
            {services.map((service, index) => (
              <div
                key={index}
                className="group p-6 bg-white rounded-3xl shadow-2xl hover:shadow-3xl transition-shadow duration-700 ease-in-out transform hover:-translate-y-2"
                data-aos="zoom-in" // Apply AOS animation
                data-aos-duration="1200" // Set animation duration
                style={{ height: '400px' }}
              >
                <div className="flex justify-center mb-6">
                  {service.icon}
                </div>
                <h3 className="text-2xl md:text-3xl font-semibold mb-4 text-gray-900 group-hover:text-blue-600 transition-colors duration-300 ease-in-out">
                  {service.title}
                </h3>
                <p className="text-gray-700 mb-6 text-sm md:text-base">
                  {service.description}
                </p>
                <Link
                  to={service.link}
                  className="block text-center bg-gradient-to-r from-teal-500 to-blue-500 text-white px-4 py-2 md:px-6 md:py-3 rounded-full transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-blue-500 hover:to-teal-500 hover:text-white hover:shadow-lg"
                >
                  Read More
                </Link>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Services;
