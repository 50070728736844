import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS is installed

const SocialMediaPage = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen mx-auto bg-white shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="relative">
          <img
            src="https://ik.imagekit.io/zfayt6inj/pexels-photo-248533.jpeg?updatedAt=1729494414332"
            alt="Instagram Money Guide"
            className="w-full h-[600px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-5">
            <h1 className="text-3xl md:text-4xl font-bold text-white text-center">
              How to Earn Money from Instagram in 2025 with Webiq
            </h1>
            <p className="mt-2 text-lg italic text-gray-300">
              A Strategic Guide to Boosting Your Revenue through Instagram
            </p>
          </div>
        </div>

        {/* Content Section */}
        <div className="md:p-20 p-6 space-y-8">
          <p className="text-gray-800 text-lg leading-relaxed">
            Instagram has become one of the most effective online platforms for generating revenue. With over a billion users, it provides immense opportunities for influencers, entrepreneurs, and businesses alike. At Webiq, we help you maximize these opportunities through strategic approaches that align with your personal or business goals.
          </p>

          {/* Sections Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-duration="800"
                className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 border-l-4 border-green-600 relative overflow-hidden"
              >
                <h3 className="text-2xl font-semibold mb-3 text-green-600 text-left">{section.title}</h3>
                <p className="text-gray-600 mb-2 text-left">{section.content}</p>
                {section.list && (
                  <ul className="list-disc pl-6 text-left mt-2 text-gray-600">
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.image && (
                  <img
                    src={section.image}
                    alt={section.title}
                    className="mt-4 rounded-lg shadow transition-transform transform"
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Conclusion Section */}
        <div className="bg-green-100 p-6 rounded-lg shadow-lg md:mx-20 transition-shadow hover:shadow-xl md:mb-10">
          <h4 className="text-lg font-semibold text-green-700">
            Conclusion: Instagram in 2025 - The Future is Bright
          </h4>
          <p className="text-gray-700 mt-3">
            In 2025, Instagram remains a powerhouse for influencers and businesses alike, offering endless opportunities to generate income. Whether you’re diving into affiliate marketing, selling digital products, or forming brand partnerships, Webiq is here to guide and support you every step of the way.
          </p>
        </div>
      </div>
    </div>
  );
};

// Section data with images (some sections may not have images)
const sections = [
  {
    title: '1. Instagram Affiliate Marketing',
    content:
      'At Webiq, we assist influencers in selecting the right affiliate programs that match their audience\'s interests.',
    list: ['Choose the Right Affiliate Program', 'Understand Your Audience', 'Leverage Webiq’s Marketing Tools'],
    image: 'https://ik.imagekit.io/zfayt6inj/social1.png?updatedAt=1729494135440',
  },
  {
    title: '2. Sponsored Posts and Partnerships',
    content:
      'Sponsored posts and brand partnerships are key revenue streams. Webiq specializes in helping you build meaningful relationships with brands that fit your niche.',
    list: ['Build a Niche Audience', 'Create a Professional Media Kit', 'Pitch to Brands with Webiq'],
    image: 'https://ik.imagekit.io/zfayt6inj/social2.png?updatedAt=1729494135552',
  },
  {
    title: '3. Selling Digital Products',
    content:
      'Selling digital products like eBooks, courses, or templates can be a lucrative way to make money from Instagram. Webiq helps you design and market these products with precision.',
    list: ['Develop a Digital Product', 'Promote Your Product', 'Offer Discounts and Incentives with Webiq'],
    image: 'https://ik.imagekit.io/zfayt6inj/social4.png?updatedAt=1729494135721',
  },
  {
    title: '4. Collaborating with Influencers',
    content:
      'Collaborating with fellow influencers is a great way to expand your reach and boost your income. Webiq connects influencers for mutually beneficial partnerships.',
    list: ['Find the Right Influencers', 'Reach Out with Confidence', 'Promote Collaborations with Webiq'],
    image: 'https://ik.imagekit.io/zfayt6inj/social5.png?updatedAt=1729494135636',
  },
  {
    title: '5. Offering Paid Content',
    content:
      'Webiq’s platform allows creators to monetize premium content through subscription models, providing exclusive material for followers.',
    list: ['Set Up Instagram Subscriptions', 'Promote Paid Content Effectively'],
    image: 'https://ik.imagekit.io/zfayt6inj/social3.png?updatedAt=1729494135316',
  },
];

export default SocialMediaPage;
