import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ChooseUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="py-12 bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 md:px-6">
        {/* Section Header */}
        <div className="text-center mb-10" data-aos="fade-in">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
            Why Choose Us?
          </h2>
          <p className="text-base md:text-lg text-gray-600 mt-4">
            A dynamic team with years of experience in delivering high-quality websites and apps. Here’s why we stand out:
          </p>
        </div>

        {/* Card Layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Card 1 */}
          <div data-aos="fade-up" className="flex flex-col items-center text-center bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
            <i className="fas fa-users text-teal-600 text-5xl md:text-6xl mb-4"></i>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Experienced Team</h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              Our team consists of professionals with years of experience in building responsive websites and innovative apps.
            </p>
          </div>

          {/* Card 2 */}
          <div data-aos="fade-up" data-aos-delay="200" className="flex flex-col items-center text-center bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
            <i className="fas fa-mobile-alt text-purple-600 text-5xl md:text-6xl mb-4"></i>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Mobile-Friendly Design</h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              We specialize in creating responsive, user-friendly websites that work beautifully on all devices.
            </p>
          </div>

          {/* Card 3 */}
          <div data-aos="fade-up" data-aos-delay="400" className="flex flex-col items-center text-center bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
            <i className="fas fa-cloud-upload-alt text-teal-600 text-5xl md:text-6xl mb-4"></i>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800">App Development</h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              We don’t just build websites – we publish apps on the Google Play Store that enhance your digital presence.
            </p>
          </div>

          {/* Card 4 */}
          <div data-aos="fade-up" data-aos-delay="600" className="flex flex-col items-center text-center bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
            <i className="fas fa-paint-brush text-purple-600 text-5xl md:text-6xl mb-4"></i>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Modern & Attractive UI</h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              We focus on visually appealing designs that combine functionality with aesthetics to create an outstanding user experience.
            </p>
          </div>

          {/* Card 5 */}
          <div data-aos="fade-up" data-aos-delay="800" className="flex flex-col items-center text-center bg-white p-6 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300">
            <i className="fas fa-handshake text-teal-600 text-5xl md:text-6xl mb-4"></i>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800">Client-Centered Approach</h3>
            <p className="mt-2 text-sm md:text-base text-gray-600">
              Our client-first philosophy ensures that we understand your business needs and exceed your expectations in every project.
            </p>
          </div>
        </div>

        {/* Call to Action */}
        <div className="text-center mt-12 md:mt-16">
          <h3 className="text-2xl md:text-3xl font-bold text-gray-800" data-aos="zoom-in">
            Let’s Bring Your Vision to Life!
          </h3>
          <p className="text-base md:text-lg text-gray-600 mt-4" data-aos="zoom-in">
            Contact us today to learn more about how we can help your business grow.
          </p>
          <a href="#contact">
            <button className="mt-6 px-10 py-3 md:px-12 md:py-4 bg-gradient-to-r from-teal-600 to-blue-500 text-white rounded-full shadow-lg hover:bg-teal-700 transition duration-300" data-aos="zoom-in">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
