import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const AppDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Hero Section */}
      <div
        className="relative flex flex-col items-center justify-center h-[600px] bg-cover bg-center"
        style={{ backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg')` }}
        data-aos="fade-in"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
        <div className="relative text-center z-10 p-4 md:p-8">
          <h1 className="text-4xl md:text-7xl font-extrabold text-white mb-2">Mobile App Development</h1>
          <p className="text-lg md:text-2xl font-bold text-sky-400 tracking-wide mb-8">Build Your Dream App</p>
          <a
            href="tel:+919812887097"
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 md:py-4 md:px-10 rounded-lg shadow-lg hover:bg-blue-600 transition"
          >
            Get a Free Consultation
          </a>
        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-4">Transform Your Idea Into Reality</h2>
        <p className="text-base md:text-lg text-gray-600 max-w-3xl mb-8">
          We develop mobile applications that combine stunning design with advanced functionality. Our apps are built for scalability, security, and user experience.
        </p>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 px-6 md:px-12 bg-white shadow-lg rounded-lg mx-4" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 text-center mb-12">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: "Tailored to Your Needs", description: "We create custom solutions that match your business goals.", icon: "https://cdn-icons-gif.flaticon.com/15374/15374739.gif" },
            { title: "Performance Focused", description: "High-performance apps for better user engagement.", icon: "https://cdn-icons-gif.flaticon.com/16780/16780813.gif" },
            { title: "Top-Notch Security", description: "Secure mobile applications to protect your data.", icon: "https://cdn-icons-gif.flaticon.com/15597/15597321.gif" },
            { title: "Scalable Solutions", description: "Our apps grow with your business needs.", icon: "https://cdn-icons-gif.flaticon.com/15578/15578468.gif" },
          ].map((item, idx) => (
            <div key={idx} className="bg-gray-100 shadow-md rounded-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="zoom-in" data-aos-delay={idx * 100}>
              <img src={item.icon} alt={item.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600 text-center">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-8">Our Approach</h2>
        <div className="flex flex-col lg:flex-row gap-8">
          {[
            { title: "Planning & Strategy", description: "We begin with thorough research and planning.", icon: "https://cdn-icons-gif.flaticon.com/16059/16059815.gif" },
            { title: "Design & UI/UX", description: "Our design team ensures a user-friendly and modern look.", icon: "https://cdn-icons-gif.flaticon.com/14183/14183452.gif" },
            { title: "Development", description: "We develop scalable, high-quality applications.", icon: "https://cdn-icons-gif.flaticon.com/17490/17490054.gif" },
            { title: "Launch & Support", description: "Our support team ensures smooth launch and post-launch support.", icon: "https://cdn-icons-gif.flaticon.com/15401/15401379.gif" },
          ].map((step, idx) => (
            <div key={idx} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-md transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="fade-up" data-aos-delay={idx * 100}>
              <img src={step.icon} alt={step.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-6 md:px-12 bg-gradient-to-r from-green-300 via-blue-200 to-indigo-300 text-center text-gray-800">
        <h2 className="text-3xl md:text-5xl font-extrabold mb-4">Get Started with Your Mobile App Today</h2>
        <p className="mt-4 mb-10 text-lg md:text-xl">Our team is ready to help you design, develop, and launch your next mobile app project. Let's bring your vision to life!</p>
        <a
          href="tel:+919812887097"
          className="bg-white text-teal-600 py-3 px-6 md:py-4 md:px-10 rounded-lg shadow-md hover:bg-teal-100 transition-transform transform hover:scale-105 text-lg"
        >
         Request a Consultation
        </a>
      </section>
    </div>
  );
};

export default AppDevelopment;
