import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel theme styles

const Products = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const products = [
    {
      title: 'Bud and Tulip',
      description: 'A sophisticated e-commerce solution featuring an intuitive admin dashboard and a seamless shopping experience.',
      link: 'https://budandtulips.com',
      image: 'https://ik.imagekit.io/devhm/budandtulips.com_(Asus%20Zenbook%20Fold).png?updatedAt=1727725937487',
      rating: 4.5,
    },
    {
      title: 'Dodun Soft Solution',
      description: 'A professional portfolio showcasing expertise with a built-in enquiry email setup for easy client interactions.',
      link: 'https://dodunsoftsolutions.com/',
      image: 'https://ik.imagekit.io/devhm/dodun.png?updatedAt=1727726160157',
      rating: 4.8,
    },
    {
      title: 'Shri Satya Technology',
      description: 'A sleek portfolio site that highlights technology solutions and services, designed to impress clients.',
      link: 'https://shrisatyait.com/',
      image: 'https://ik.imagekit.io/devhm/shrisatya.png?updatedAt=1727726160140',
      rating: 4.6,
    },
    {
      title: 'My Visa Point',
      description: 'An online platform that simplifies the visa application process with expert guidance and resources.',
      link: 'https://myvisapoint.com',
      image: 'https://ik.imagekit.io/devhm/visapoint.png?updatedAt=1727726160263',
      rating: 4.7,
    },
    {
      title: 'Marketome',
      description: 'A comprehensive marketplace solution that connects buyers and sellers for a seamless shopping experience.',
      link: 'https://marketome.com',
      image: 'https://ik.imagekit.io/devhm/marketome.png?updatedAt=1727726160118',
      rating: 4.9,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-white py-16 px-4 overflow-hidden"> {/* Add overflow-hidden to prevent scroll */}
      <div className="max-w-full mx-auto">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-8 text-gray-800">
          Discover Our Premium Products
        </h1>
        <Slider {...settings}>
          {products.map((product, index) => (
            <div
              key={index}
              className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-xl overflow-hidden transform transition-transform hover:scale-105 mx-1" // Change mx-2 to mx-1 for tighter spacing
              data-aos="fade-up"
              data-aos-delay={index * 200}
            >
              <img
                src={product.image}
                alt={product.title}
                className="w-full h-32 sm:h-48 object-cover rounded-lg mb-4" // Adjust height to maintain aspect ratio
              />
              <h2 className="text-lg sm:text-xl md:text-2xl font-semibold text-gray-800 mb-2">{product.title}</h2>
              <p className="text-sm sm:text-base text-gray-700 mb-4">{product.description}</p>
              <div className="flex items-center mb-4">
                <span className="text-yellow-500 text-lg sm:text-xl">{`★ ${product.rating}`}</span>
                <span className="text-gray-500 text-xs sm:text-sm ml-2">(Based on {Math.floor(Math.random() * 100) + 1} reviews)</span>
              </div>
              <a
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-center bg-gradient-to-r from-blue-500 to-teal-500 text-white px-6 py-2 rounded-full transition-all duration-300 ease-in-out hover:bg-gradient-to-r hover:from-teal-500 hover:to-blue-600 hover:shadow-lg"
              >
                Visit {product.title}
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Products;
