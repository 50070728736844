import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const SEO = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Hero Section */}
      <div
        className="relative flex flex-col items-center justify-center h-[600px] bg-cover bg-center"
        style={{ backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg')` }}
        data-aos="fade-in"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="relative text-center z-10 p-4 md:p-8">
          <h1 className="text-4xl md:text-7xl font-extrabold text-white mb-2">SEO Services</h1>
          <p className="text-lg md:text-2xl font-bold text-sky-400 tracking-wide mb-8">Rank Higher, Reach More</p>
          <a
            href="tel:+919812887097"
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 md:py-4 md:px-10 rounded-lg shadow-lg hover:bg-blue-600 transition"
          >
            Get a Free SEO Audit
          </a>
        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-4">Get Found on Search Engines</h2>
        <p className="text-base md:text-lg text-gray-600 max-w-3xl mb-8">
          We help your website show up higher in search engine results like Google, making it easier for people to find you.
        </p>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 px-6 md:px-12 bg-white shadow-lg rounded-lg mx-4" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 text-center mb-12">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: "Higher Rankings", description: "We boost your site to the top of search results.", icon: "https://cdn-icons-gif.flaticon.com/15584/15584494.gif" },
            { title: "More Traffic", description: "Get more visitors to your website.", icon: "https://cdn-icons-gif.flaticon.com/15594/15594419.gif" },
            { title: "Better Leads", description: "Attract people who are ready to buy.", icon: "https://cdn-icons-gif.flaticon.com/14255/14255799.gif" },
            { title: "Measurable Results", description: "We track your progress and show you the results.", icon: "https://cdn-icons-gif.flaticon.com/17864/17864892.gif" },
          ].map((item, idx) => (
            <div key={idx} className="bg-gray-100 shadow-md rounded-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="zoom-in" data-aos-delay={idx * 100}>
              <img src={item.icon} alt={item.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600 text-center">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-8">Our Approach</h2>
        <div className="flex flex-col lg:flex-row gap-8">
          {[
            { title: "Keyword Research", description: "We find the best keywords to target.", icon: "https://cdn-icons-gif.flaticon.com/12035/12035159.gif" },
            { title: "On-Page SEO", description: "We optimize your website's content and structure.", icon: "https://cdn-icons-gif.flaticon.com/14447/14447492.gif" },
            { title: "Link Building", description: "We build quality backlinks to boost authority.", icon: "https://cdn-icons-gif.flaticon.com/11260/11260690.gif" },
            { title: "Ongoing Optimization", description: "We keep improving your SEO for long-term success.", icon: "https://cdn-icons-gif.flaticon.com/16059/16059845.gif" },
          ].map((step, idx) => (
            <div key={idx} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-md transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="fade-up" data-aos-delay={idx * 100}>
              <img src={step.icon} alt={step.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-6 md:px-12 bg-gradient-to-r from-green-300 via-blue-200 to-indigo-300 text-center text-gray-800">
        <h2 className="text-3xl md:text-5xl font-extrabold mb-4">Improve Your SEO Today</h2>
        <p className="mt-4 mb-10 text-lg md:text-xl">Ready to increase your search engine rankings and attract more customers? Let us help you get there!</p>
        <a
          href="tel:+919812887097"
          className="bg-white text-teal-600 py-3 px-6 md:py-4 md:px-10 rounded-lg shadow-md hover:bg-teal-100 transition-transform transform hover:scale-105 text-lg"
        >
        Request a Free SEO Audit
        </a>
      </section>
    </div>
  );
};

export default SEO;
