import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const WebDevelopment = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Hero Section */}
      <div
        className="relative flex flex-col items-center justify-center h-[600px] bg-cover bg-center"
        style={{ backgroundImage: `url('https://images.pexels.com/photos/1181406/pexels-photo-1181406.jpeg')` }}
        data-aos="fade-in"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
        <div className="relative text-center z-10 p-4 md:p-8">
          <h1 className="text-4xl md:text-7xl font-extrabold text-white mb-2">Web Development</h1>
          <p className="text-lg md:text-2xl font-bold text-sky-400 tracking-wide mb-8">Build a Website that Stands Out</p>
          <a
            href="tel:+919812887097"
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 md:py-4 md:px-10 rounded-lg shadow-lg hover:bg-blue-600 transition"
          >
            Start Your Project
          </a>

        </div>
      </div>

      {/* Introduction Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-4">Create a Website that Grows Your Business</h2>
        <p className="text-base md:text-lg text-gray-600 max-w-3xl mb-8">
          We design and build fast, secure, and responsive websites that give your users a great experience. Let us help you create a website that meets your business needs.
        </p>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 px-6 md:px-12 bg-white shadow-lg rounded-lg mx-4" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 text-center mb-12">Why Choose Us?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: "Custom Designs", description: "We create unique websites tailored to your brand.", icon: "https://cdn-icons-gif.flaticon.com/14183/14183490.gif" },
            { title: "Responsive Across Devices", description: "Your website will look great on mobile, tablet, and desktop.", icon: "https://cdn-icons-gif.flaticon.com/14447/14447558.gif" },
            { title: "Fast & Secure", description: "We optimize your site for speed and security.", icon: "https://cdn-icons-gif.flaticon.com/14447/14447768.gif" },
            { title: "SEO Friendly", description: "Our websites are built to rank high on search engines.", icon: "https://cdn-icons-gif.flaticon.com/15712/15712984.gif" },
          ].map((item, idx) => (
            <div key={idx} className="bg-gray-100 shadow-md rounded-lg p-6 flex flex-col items-center transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="zoom-in" data-aos-delay={idx * 100}>
              <img src={item.icon} alt={item.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600 text-center">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Our Approach Section */}
      <section className="py-16 px-6 md:px-12 text-left" data-aos="fade-up">
        <h2 className="text-3xl md:text-5xl font-extrabold text-gray-800 mb-8">Our Approach</h2>
        <div className="flex flex-col lg:flex-row gap-8">
          {[
            { title: "Research & Planning", description: "We understand your goals and plan the perfect solution.", icon: "https://cdn-icons-gif.flaticon.com/17556/17556514.gif" },
            { title: "Design", description: "We craft beautiful, user-friendly designs.", icon: "https://cdn-icons-gif.flaticon.com/15591/15591404.gif" },
            { title: "Development", description: "Our team builds fast, secure, and scalable websites.", icon: "https://cdn-icons-gif.flaticon.com/17122/17122509.gif" },
            { title: "Launch & Support", description: "We ensure a smooth launch and provide ongoing support.", icon: "https://cdn-icons-gif.flaticon.com/17122/17122740.gif" },
          ].map((step, idx) => (
            <div key={idx} className="flex flex-col items-center text-center bg-gray-100 p-6 rounded-lg shadow-md transition-transform duration-300 hover:shadow-lg hover:scale-105" data-aos="fade-up" data-aos-delay={idx * 100}>
              <img src={step.icon} alt={step.title} className="w-16 h-16 mb-4" />
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-6 md:px-12 bg-gradient-to-r from-green-300 via-blue-200 to-indigo-300 text-center text-gray-800">
        <h2 className="text-3xl md:text-5xl font-extrabold mb-4">Ready to Build Your Website?</h2>
        <p className="mt-4 mb-10 text-lg md:text-xl">Whether you need a brand new website or want to redesign an existing one, we're here to help. Let's create something amazing together!</p>
        <a
          href="tel:+919812887097"
          className="bg-white text-teal-600 py-3 px-6 md:py-4 md:px-10 rounded-lg shadow-md hover:bg-teal-100 transition-transform transform hover:scale-105 text-lg"
        >
          Start Your Project
        </a>
      </section>
    </div>
  );
};

export default WebDevelopment;
