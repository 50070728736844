import React, { useEffect } from 'react';
import Slider from 'react-slick'; // Import the slider
import 'slick-carousel/slick/slick.css'; // Import slick-carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel theme styles
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos';

const teamMembers = [
  {
    name: 'Ashutosh',
    role: 'Professional Digital Marketer',
    description: 'Digital marketer specializing in Facebook ads and social media growth.',
    image: 'https://ik.imagekit.io/zfayt6inj/IMG-20241013-WA0053.jpg?updatedAt=1728803139537',
    animation: 'fade-right',
  },
  {
    name: 'Harsh Mangal',
    role: 'Flutter Developer',
    description: 'Developing cross-platform mobile apps with Flutter for seamless user experiences.',
    image: 'https://ik.imagekit.io/zfayt6inj/mangal%20(1).jpg?updatedAt=1728046229654',
    animation: 'zoom-in',
  },
  {
    name: 'Harsh Bhardwaj',
    role: 'Full Stack Developer',
    description: 'Building dynamic web applications with frontend, backend, and database expertise.',
    image: 'https://ik.imagekit.io/zfayt6inj/IMG_20241004_183351.jpg?updatedAt=1728047065279',
    animation: 'fade-up',
  },
  {
    name: 'Roopam',
    role: 'WordPress Developer',
    description: 'Designing custom websites with WordPress for flexible and scalable solutions.',
    image: 'https://ik.imagekit.io/zfayt6inj/IMG-20241004-WA0130.jpg?updatedAt=1728037707835',
    animation: 'fade-right',
  },
  {
    name: 'Sameer',
    role: 'App Developer',
    description: 'Creating innovative mobile applications to enhance user engagement and functionality.',
    image: 'https://ik.imagekit.io/zfayt6inj/IMG-20241004-WA0131.jpg?updatedAt=1728037707435',
    animation: 'fade-left',
  },
];

const TeamPage = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
    AOS.refresh();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="min-h-1/2 w-full overflow-x-hidden bg-cover bg-center"
      style={{
        backgroundImage: `url('https://ik.imagekit.io/devhm/pexels-fauxels-3183150.jpg?updatedAt=1727725462697')`, // Replace with your background image URL
        backgroundColor: 'rgba(10, 25, 70, 0.8)', // Dark blue tint
      }}
    >
      <div className="bg-black bg-opacity-50 backdrop-blur-lg py-10">
        <div className="container mx-auto px-4 text-center">
          <h1 className="text-4xl font-extrabold text-teal-400 mb-4">Our Team</h1>
          <p className="text-lg text-gray-300 mb-12">
            Get to know the talented team behind our success.
          </p>
          <Slider {...settings}>
            {teamMembers.map((member, index) => (
              <div
                key={index}
                className="bg-white bg-opacity-20 backdrop-blur-lg rounded-lg shadow-lg p-6 mx-2 transition-transform transform hover:scale-105"
                data-aos={member.animation}
                data-aos-delay={index * 100}
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="rounded-full border-4 border-teal-400 w-24 h-30 mx-auto mb-4"
                />
                <h2 className="text-xl font-semibold text-white mb-2">{member.name}</h2>
                <p className="text-teal-400 font-medium mb-2">{member.role}</p>
                <p className="text-gray-300 text-sm">{member.description}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
