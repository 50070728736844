import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS is installed

const SEOBlog = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen mx-auto bg-white shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="relative">
          <img
            src="https://ik.imagekit.io/zfayt6inj/pexels-photo-270637.jpeg?updatedAt=1729494684798"
            alt="SEO Guide"
            className="w-full  h-[600px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-5">
            <h1 className="text-3xl md:text-4xl font-bold text-white text-center">Unlocking SEO Success: Strategies for 2024</h1>
            <p className="mt-2 text-lg italic text-gray-300">A Comprehensive Guide to Boosting Your Online Presence</p>
          </div>
        </div>

        {/* Content Section */}
        <div className="md:p-20 p-6 space-y-8">
          <p className="text-gray-800 text-lg leading-relaxed">
            In the ever-evolving world of digital marketing, understanding how to effectively rank your website on Google is crucial. With a multitude of strategies at your disposal, this article will delve into essential practices to enhance your search engine optimization (SEO).
          </p>

          {/* Sections Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-duration="800"
                className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 border-l-4 border-blue-600 relative overflow-hidden"
              >
                <h3 className="text-2xl font-semibold mb-3 text-blue-600 text-left ">{section.title}</h3>
                <p className="text-gray-600 mb-2 text-left ">{section.content}</p>
                {section.list && (
                  <ul className="list-disc pl-6 text-left mt-2 text-gray-600">
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.image && (
                  <img
                    src={section.image}
                    alt={section.title}
                    className="mt-4 rounded-lg shadow transition-transform transform"
                  />
                )}
                </div>
            ))}
          </div>
        </div>

        {/* Conclusion Section */}
        <div className="bg-blue-100 p-6 rounded-lg shadow-lg md:mx-20 transition-shadow hover:shadow-xl md:mb-10">
          <h4 className="text-lg font-semibold text-blue-700">Final Thoughts: SEO is a Journey</h4>
          <p className="text-gray-700 mt-3">
            Mastering SEO takes time and effort, but the rewards are worth it. By implementing these strategies and remaining adaptable to change, you'll be better positioned to achieve higher rankings and attract more visitors to your site.
          </p>
        </div>
      </div>
    </div>
  );
};

// Section data with images
const sections = [
  {
    title: '1. Understanding Google’s Algorithm',
    content: 'Google’s ranking algorithm is intricate and changes frequently. The key factors include:',
    list: ['Content Relevance', 'Website Quality', 'User Experience (UX)', 'Mobile Compatibility'],
    image: 'https://ik.imagekit.io/zfayt6inj/seo1.png?updatedAt=1729487797554',
  },
  {
    title: '2. On-Page Optimization',
    content: 'Optimizing your pages involves adjusting various elements for better visibility.',
    list: ['Title Tags', 'Meta Descriptions', 'Header Tags', 'URL Structure'],
    image: 'https://ik.imagekit.io/zfayt6inj/seo4.png?updatedAt=1729487797493',
  },
  {
    title: '3. Building Quality Backlinks',
    content: 'High-quality backlinks from authoritative sites boost your credibility and ranking.',
    list: ['Guest Blogging', 'Outreach Strategies', 'Creating Shareable Content'],
  },
  {
    title: '4. Crafting High-Quality Content',
    content: 'Content is the cornerstone of SEO. It should be valuable, engaging, and well-researched.',
    list: ['Answer User Queries', 'Incorporate Visuals', 'Update Regularly'],
  },
  {
    title: '5. Technical SEO Best Practices',
    content: 'Technical SEO ensures your site is crawlable and indexable by search engines.',
    list: ['Improve Page Speed', 'Mobile Responsiveness', 'SSL Certificate'],
    image: 'https://ik.imagekit.io/zfayt6inj/seo5.png?updatedAt=1729487797568',
  },
  {
    title: '6. Effective Keyword Research',
    content: 'Keyword research is vital to understanding what your audience is searching for.',
    list: ['Identify Relevant Keywords', 'Utilize Keyword Tools', 'Incorporate Keywords Naturally'],
    image: 'https://ik.imagekit.io/zfayt6inj/seo2.png?updatedAt=1729487797514',
  },
  {
    title: '7. Local SEO with Google My Business',
    content: 'Optimizing your Google My Business profile can greatly enhance local search visibility.',
    list: ['Complete Your Profile', 'Encourage Customer Reviews', 'Post Regular Updates'],
  },
  {
    title: '8. Monitoring SEO Performance',
    content: 'Regularly track your SEO metrics to refine your strategies effectively.',
    list: ['Analyze Organic Traffic', 'Track Keyword Rankings', 'Evaluate Bounce Rates'],
  },
];

export default SEOBlog;
