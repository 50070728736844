import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS is installed

const AppDevelopmentBlog = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-screen mx-auto bg-white shadow-lg overflow-hidden">
        {/* Header Section */}
        <div className="relative">
          <img
            src="https://ik.imagekit.io/zfayt6inj/pexels-photo-89955.webp?updatedAt=1729495583647"
            alt="App Development Trends"
            className="w-full h-[600px] object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex flex-col items-center justify-center p-5">
            <h1 className="text-3xl md:text-4xl font-bold text-white text-center">
              Future of App Development: 2025 Trends & Technologies
            </h1>
            <p className="mt-2 text-lg italic text-gray-300">
              Discover the innovations shaping the future of mobile applications
            </p>
          </div>
        </div>

        {/* Content Section */}
        <div className="md:p-20 p-6 space-y-8">
          <p className="text-gray-800 text-lg leading-relaxed">
            The landscape of app development is evolving at a rapid pace, driven by advancements in technology, changing user expectations, and new opportunities. In 2025, app developers will need to stay ahead of the curve to meet growing demands for smarter, faster, and more secure applications. Below, we explore key trends and technologies that will define the future of app development.
          </p>

          {/* Sections Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-duration="800"
                className="bg-white rounded-lg shadow-lg p-6 transition-transform transform hover:scale-105 border-l-4 border-teal-600 relative overflow-hidden"
              >
                <h3 className="text-2xl font-semibold mb-3 text-teal-600 text-left">{section.title}</h3>
                <p className="text-gray-600 mb-2 text-left">{section.content}</p>
                {section.list && (
                  <ul className="list-disc pl-6 text-left mt-2 text-gray-600">
                    {section.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {section.image && (
                  <img
                    src={section.image}
                    alt={section.title}
                    className="mt-4 rounded-lg shadow transition-transform transform"
                  />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Conclusion Section */}
        <div className="bg-teal-100 p-6 rounded-lg shadow-lg md:mx-20 transition-shadow hover:shadow-xl md:mb-10">
          <h4 className="text-lg font-semibold text-teal-700">
            Conclusion: Embrace the Future of App Development
          </h4>
          <p className="text-gray-700 mt-3">
            The future of app development holds exciting possibilities. Developers will need to harness the latest trends and technologies to create apps that deliver exceptional user experiences and meet the demands of a connected world. By embracing these changes and preparing for the future, app developers can unlock new opportunities and continue to thrive in this dynamic field.
          </p>
        </div>
      </div>
    </div>
  );
};

// Section data with images (some sections may not have images)
const sections = [
  {
    title: '1. Artificial Intelligence and Machine Learning',
    content:
      'AI and ML are transforming the way apps interact with users. From personalized content to voice recognition, these technologies enable smarter apps that can adapt to individual preferences.',
    list: ['AI-powered user experiences', 'Predictive analytics for personalization', 'Voice and facial recognition'],
    image: 'https://ik.imagekit.io/zfayt6inj/app1.png?updatedAt=1729497369237',
  },
  {
    title: '2. 5G and Edge Computing',
    content:
      'The roll-out of 5G networks is set to revolutionize app performance by providing faster data transfer speeds and lower latency. Coupled with edge computing, this will enable real-time processing closer to the data source.',
    list: ['Real-time applications', 'Faster streaming and downloads', 'Improved AR/VR experiences'],
    image: 'https://ik.imagekit.io/zfayt6inj/app2.png?updatedAt=1729497388864',
  },
  {
    title: '3. Cross-platform Development',
    content:
      'With growing demand for apps that work seamlessly across multiple platforms, cross-platform development tools like Flutter and React Native will continue to gain popularity. These tools enable developers to write code once and deploy it across iOS and Android.',
    list: ['Cost-effective app development', 'Faster time-to-market', 'Improved code reusability'],
  },
  {
    title: '4. Enhanced App Security',
    content:
      'With the increasing reliance on mobile applications, security will be more critical than ever. 2025 will see a greater emphasis on protecting user data through stronger encryption, multi-factor authentication, and secure coding practices.',
    list: ['Stronger data encryption', 'Multi-factor authentication', 'Focus on privacy and security compliance'],
  },
  {
    title: '5. Internet of Things (IoT) Integration',
    content:
      'The integration of IoT into mobile apps is expected to see substantial growth in 2025. IoT enables the connection of devices and systems, making it possible for apps to control smart home devices, monitor health metrics, and much more. Developers will need to ensure their apps can communicate efficiently with various IoT devices.',
    list: ['Smart home automation', 'Wearable device integration', 'Real-time health monitoring'],
    image: 'https://ik.imagekit.io/zfayt6inj/app6.png?updatedAt=1729497389503',
  },
  {
    title: '6. Progressive Web Apps (PWAs)',
    content:
      'Progressive Web Apps are web-based applications that provide an app-like experience on mobile devices. PWAs are gaining popularity due to their ability to work offline, fast loading times, and compatibility across different platforms. They eliminate the need for users to download apps, making them an efficient alternative to traditional mobile applications.',
    list: ['Offline capabilities', 'Faster load times', 'No installation required'],
    image: 'https://ik.imagekit.io/zfayt6inj/app7.png?updatedAt=1729497389216',
  },
  {
    title: '7. Augmented Reality and Virtual Reality',
    content:
      'AR and VR technologies are no longer limited to gaming. In 2025, they will be integrated into a variety of applications, from virtual shopping experiences to remote collaboration tools.',
    list: ['Immersive user experiences', 'Virtual shopping and product previews', 'Remote collaboration'],
  },  
];

export default AppDevelopmentBlog;
