import React from 'react';

const WhatsappWidget = () => {
  return (
    <div className='fixed bottom-4 right-4 z-50'>
      <a 
        href="https://wa.me/919812887097" 
        target="_blank" 
        rel="noopener noreferrer"
        className="whatsapp-widget w-16 h-16 flex items-center justify-center rounded-full bg-[#25D366] shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105 animate-pulse"
      >
        <i className="fa-brands fa-whatsapp fa-2xl text-white"></i>
      </a>
      <style jsx>{`
        .whatsapp-widget {
          position: relative;
        }
        
        .whatsapp-widget::before {
          content: '';
          position: absolute;
          top: -6px; /* Adjusts the position of the glow */
          left: -6px; /* Adjusts the position of the glow */
          right: -6px; /* Adjusts the position of the glow */
          bottom: -6px; /* Adjusts the position of the glow */
          border-radius: 50%;
          background: rgba(37, 211, 102, 0.6); /* Slightly transparent for glow */
          z-index: -1; /* Place behind the button */
          filter: blur(20px); /* Creates a glowing effect */
        }
      `}</style>
    </div>
  );
}

export default WhatsappWidget;
