import React, { useState, useEffect } from 'react';

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Effect to handle scroll detection
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 left-0 w-full z-50 transition-all duration-500 ${isScrolled ? 'bg-white bg-opacity-90 shadow-lg' : 'bg-transparent'}`}>
      <div className="container mx-auto flex items-center justify-between p-6">
      <a href="/" className="flex items-center text-4xl font-extrabold text-teal-500 hover:text-blue-500 transition duration-300">
          <img
            src="https://ik.imagekit.io/zfayt6inj/webiqNew.jpg?updatedAt=1729412471332"
            alt="WebIQ Logo"
            className="h-10 w-10 mr-2 rounded-3xl"
          />
          WebIQ
        </a>
        <div className="hidden md:flex flex-1 justify-end space-x-8 text-lg">
          <a href="/" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">Home</a>
          <a href="/#about" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">About</a>
          <a href="/#services" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">Services</a>
          <a href="/#project" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">Project</a>
          <a href="/#team" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">Team</a>
          <a href="/#contact" className="text-teal-500 font-bold hover:text-blue-500 transition duration-300">Contact</a>
        </div>
        {/* Mobile Menu Button */}
        <button onClick={toggleDropdown} className="md:hidden text-3xl text-teal-500 focus:outline-none">
          <i className={`fas fa-${isDropdownOpen ? 'times' : 'bars'}`}></i>
        </button>
        {/* Mobile Dropdown Menu */}
        {isDropdownOpen && (
          <div className={`fixed top-0 left-0 w-full h-full bg-white bg-opacity-70 backdrop-blur-lg z-50 transition-all duration-500 ease-in-out transform ${isDropdownOpen ? 'translate-y-0' : '-translate-y-full'}`}>
            <button
              onClick={toggleDropdown}
              className="absolute top-4 right-4 text-2xl text-gray-800 focus:outline-none"
            >
              <i className="fas fa-times"></i>
            </button>
            <div className="flex flex-col items-center space-y-6 mt-20 p-8">
              <a href="#home" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-home"></i> <span>Home</span>
              </a>
              <a href="#about" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-info-circle"></i> <span>About</span>
              </a>
              <a href="#services" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-cogs"></i> <span>Services</span>
              </a>
              <a href="#project" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-project-diagram"></i> <span>Project</span>
              </a>
              <a href="#team" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-users"></i> <span>Team</span>
              </a>
              <a href="#contact" className="w-full text-center py-4 text-xl bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-full hover:bg-teal-600 transition duration-300 flex items-center justify-center space-x-2" onClick={toggleDropdown}>
                <i className="fas fa-envelope"></i> <span>Contact</span>
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
